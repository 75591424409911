<template>
  <!-- 提数字通证限制 -->
  <div class="page">
    <h4 style="color: #409eff">提资金限制</h4>
    <!-- form表单 -->
    <el-form
      :model="ruleForm"
      label-width="140px"
      ref="ruleForm"
      :rules="rules"
    >
      <el-form-item label="*限制人手机号" prop="timeAllocation">
        <el-input
          v-model="ruleForm.timeAllocation"
          size=""
          placeholder="请输入限制人手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="提资金限制" prop="xianZhi">
        <el-radio v-model="radio" label="1">限制</el-radio>
        <el-radio v-model="radio" label="2">不限制</el-radio>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
        <el-button type="info" @click="getBack">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
const cityOptions = ["QJT", "FXB"];
export default {
  data() {
    return {
        radio: '1',
      ruleForm: {
        timeAllocation: "", // 手机号
        xianZhi:""
      },
      //   验证规则
      rules: {
        timeAllocation: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 点击提交
    submitForm(formName) {
      console.log(this.form);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 返回
    getBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 30px;
  width: 400px;
}
</style>
